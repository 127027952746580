import axios, { AxiosInstance } from 'axios';

export class ApiService {
    apiRoot: string;
    axiosInstance: AxiosInstance;
    static instance: ApiService | null = null
    private constructor(apiRoot: string) {
        this.apiRoot = apiRoot;
        this.axiosInstance = axios.create(
            {
                baseURL: apiRoot,
                headers: {
                    // "x-ms-client-principal-name": "igonnakingofpirate@outlook.com"
                },
            }
        );
    }
    static getInstance(apiRoot: string) {
        if (this.instance === null) {
            this.instance = new ApiService(apiRoot);
            return this.instance;
        }
        return this.instance;
    }

    listFilters() {
        return this.axiosInstance.get("", {
            withCredentials: true,
            params: {
                "action": "list"
            },
            maxRedirects: 0
        });
    }

    generateFilter(info: string | null) {
        // return this.axiosInstance.post("", null, {
        return this.axiosInstance.get("", {
            withCredentials: true,
            params: {
                "action": "generate",
                "info": info
            }
        });
    }

    toggleFilter(generated: string, enable: boolean) {
        // return this.axiosInstance.post("", null, {
        return this.axiosInstance.get("", {
            withCredentials: true,
            params: {
                "action": enable ? "enable" : "disable",
                "enable": "false",
                "generated": generated
            }
        });
    }


    register() {
        // return this.axiosInstance.post("", null, {
        return this.axiosInstance.get("", {
            withCredentials: true,
            params: {
                "action": "register",
            }
        });
    }


}