import React from 'react';
import { Button, Table, FormControl } from 'react-bootstrap';
import './App.css';
import { ApiService } from './service';

export interface State {
  email: string;
  filters: Filter[];
  loaded: boolean;
  message: string;
}

export interface Filter {
  generated: string;
  enabled: boolean;
  site: null | string;
}

const apiService = ApiService.getInstance("https://minirelay-public.azurewebsites.net/api/minirelay-azure-base");
const loginUrl = "http://minirelay-public.azurewebsites.net/.auth/login/google?post_login_redirect_uri=/api/LoginRedirect";


class FilterComponent extends React.Component<{}, Filter> {
  constructor(prop: Filter) {
    super({});
    this.state = prop;
  }
  async toggle(email: string) {
    await apiService.toggleFilter(email, !this.state.enabled)
    this.setState({ enabled: !this.state.enabled });
  }

  copy(text: string) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
  render() {
    return (<tr>
      <td> {this.state.site ? this.state.site : "--"}
      </td>
      <td><Button onClick={() => this.copy(this.state.generated)}>{this.state.generated}</Button></td>
      <td>
        <Button variant={this.state.enabled ? "success" : "secondary"}
          onClick={() => this.toggle(this.state.generated)}>{this.state.enabled ? "enabled" : "disabled"}</Button>
      </td>
    </tr>)
  }

}




class App extends React.Component<{}, State> {
  constructor() {
    super({});
    this.state = { filters: [], email: "", loaded: true, message: "loading data" };
    this.loadData();
  }


  private loadData() {
    setTimeout(async () => {
      try {
        const out = await apiService.listFilters();
        this.setState({ filters: out.data.response.filters });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          await apiService.register();
          this.setState({ filters: [] });
        } else {
          // @ts-ignore
          window.location = loginUrl;
        }
      }
    });
  }

  async generate(event: KeyboardEvent) {
    if (event.charCode === 13) {
      // @ts-ignore
      await apiService.generateFilter(event.target!.value);
      // @ts-ignore
      event.target!.value = "";
      this.loadData();
    }
  }

  render() {
    if (this.state.loaded) {
      return (
        <div className="App">
          <Button href={loginUrl}>login</Button>
          <Button href=".auth/logout">logout</Button>
          <Table striped bordered hover size="sm" >
            <thead>
              <th>message</th>
              <th>generated</th>
              <th>enabled</th>
            </thead>
            <tbody>
              {this.state.filters.map(filter => (
                <FilterComponent key={filter.generated} {...filter} />
              ))}
            </tbody>
          </Table>
          <FormControl
            // @ts-ignore
            onKeyPress={this.generate.bind(this)}
            placeholder="site"
            aria-label="site"
            aria-describedby="basic-addon1"
          />
        </div>
      );
    }
    else {
      return (
        <div className="App">
        </div>
      )
    }
  }
}

export default App;
